<template>
  <div>
    <v-row justify="center">
      <!--  대리점 로그인    -->
      <v-col
        cols="12"
        xl="6"
        sm="8"
        md="8"
      >
        <v-row>
          <!--  대리점일때        -->
          <template v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 34">
            <!--
            <v-col
              cols="12"
              xl="6"
              md="6"
              sm="6"
            >
              <analytic-one-card
                main-icon-name="mdi-hand-pointing-up"
                main-icon-background-color-class="info lighten-5"
                main-icon-text-color="info--text"
                sub-heading-text=""
                :heading-text="directCount"
                bottom-button-text="직접모집대리점"
                bottom-button-icon="mdi-arrow-down-drop-circle-outline"
                bottom-button-hover-class="hover:bg-info"
                style="cursor:pointer;"
                @searchFormEvent="this.searchFormEvent"
              />
            </v-col>
            <v-col
              cols="12"
              xl="6"
              md="6"
              sm="6"
            >
              <analytic-two-card
                main-icon-name="mdi-account-multiple"
                main-icon-background-color-class="primary lighten-5"
                main-icon-text-color="primary--text"
                sub-heading-text=""
                :heading-text="inDirectCount"
                bottom-button-text="간접모집대리점"
                bottom-button-icon="mdi-arrow-down-drop-circle-outline"
                bottom-button-hover-class="hover:bg-primary"
              />
            </v-col>
            -->
            <v-col
              cols="6"
            >
              <div>
                <crypto-currency-card
                  bg-card-color="primary"
                  heading="직접모집대리점"
                  :sub-heading="directCount"
                  main-icon-text-color="primary--text text--lighten-4"
                  main-icon-name="mdi-lighthouse-on"
                  @searchName="this.searchName"
                  style="cursor:pointer;"
                />
              </div>
            </v-col>
            <v-col
              cols="6"
            >
              <div>
                <crypto-currency-card
                  bg-card-color="red"
                  heading="간접모집대리점"
                  :sub-heading="inDirectCount"
                  main-icon-text-color="warning--text text--lighten-4"
                  main-icon-name="mdi-handshake-outline"
                  style="cursor:pointer;"
                />
              </div>
            </v-col>
          </template>
          <template v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 33">
            <v-col
              cols="6"
            >
              <div @click="agencyTabClick(1)">
                <crypto-currency-card
                  bg-card-color="primary"
                  heading="소속대리점"
                  :sub-heading="directCount"
                  main-icon-text-color="primary--text text--lighten-4"
                  main-icon-name="mdi-lighthouse-on"
                  @searchName="this.searchName"
                  style="cursor:pointer;"
                />
              </div>
            </v-col>
            <v-col
              cols="6"
            >
              <div @click="agencyTabClick(2)">
                <crypto-currency-card
                  bg-card-color="red"
                  heading="추천지사"
                  :sub-heading="inDirectCount"
                  main-icon-text-color="warning--text text--lighten-4"
                  main-icon-name="mdi-handshake-outline"
                  @searchName="this.searchName"
                  style="cursor:pointer;"
                />
              </div>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <base-card>
              <v-card-text>

                <v-row dense>
                  <v-col id="search_date">
                    <v-menu
                      ref="menuTwo"
                      v-model="menuTwo"
                      class="text-caption text-lg-subtitle-1"
                      max-width="290"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{on, attrs}">
                        <v-combobox
                          v-model="dateRangeText"
                          prepend-icon="mdi-calendar"
                          readonly
                          dense
                          v-bind="attrs"
                          class="text-caption text-lg-subtitle-1"
                          hide-details
                          hide-no-data
                          outlined
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        scrollable
                        locale="kr"

                      >
                        <v-spacer/>
                        <v-btn
                          text
                          color="primary"
                          @click="menuTwo = false"
                        >
                          취소
                        </v-btn>
                        <!--
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuTwo.save(dates)"
                        >
                          확인
                        </v-btn>
                        -->

                        <v-btn
                          text
                          color="primary"
                          @click="datesSave(dates)"
                        >
                          확인
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>

              <search-add
                :search-list="searchList"
                @searchFormEvent="searchFormEvent"
              />
            </base-card>
          </v-col>
        </v-row>
        <div class="d-flex pr-1 justify-space-between pb-0">
          <div class="d-flex align-center">
            <v-icon
              small
              color="primary"
              class="mr-3"
            >
              mdi-circle
            </v-icon>
            <h5 class="mb-0 mr-0 font-weight-bold">
              {{ loggedInUser.dealerKind == 33 ? selectedMenu : '모집대리점' }}
            </h5>
          </div>
        </div>
        <v-row>
          <v-col>
            <base-card>
              <v-card-text>
                <v-simple-table style="width:100%;">
                  <thead style="display: inline-table; width: 100%;">
                  <tr>
                    <th
                      class="text-left"
                      style="width: 70px;"
                    >
                      순번
                    </th>
                    <th style="width: calc(100% - 270px)">
                      <!--                        {{ loggedInUser.dealerKind && loggedInUser.dealerKind == 33 ? '소속대리점' : '대리점명' }}-->
                      대리점명
                    </th>
                    <th class="text-center text-caption"
                    style="width: 100px;">
                      <!--                        {{ loggedInUser.dealerKind && loggedInUser.dealerKind == 33 ? '소속대리점' : '대리점명' }}-->
                      <template v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 33">
                        직접/간접모집<br />대리점수
                      </template>
                      <template v-else>
                        모집<br />대리점수
                      </template>
                    </th>
                    <th
                      class="text-center text-caption"
                      style="width:100px;"
                    >
                      {{ loggedInUser.dealerKind && loggedInUser.dealerKind == 33 ? '소속' : '대리점' }}
                      <br>
                      {{
                        loggedInUser.dealerKind && selectedMenu == '소속대리점' || loggedInUser.dealerKind == 34 ? '가맹점수' : '대리점수'
                      }}
                    </th>
                  </tr>
                  <tr v-if="agencyList.length === 0">
                    <td
                      class="text-center mt-4 mb-0 mr-0 font-weight-bold"
                      colspan="3"
                    >
                      조회된 정보가 없습니다.
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan="4" style="width:100%; padding: 0px 5px;">
                      <v-expansion-panels
                        flat
                        class="mx-0"
                        style="padding:1px 0px"

                        v-for="(item, index) in agencyList"
                        :key="index"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            hide-actions
                            class="text-caption mx-0 "
                            style="padding:16px 7px 16px 24px"


                          >
                            <v-row no-gutters>
                              <v-col style="width:70px; flex-basis: unset;">
                                {{ item.no }}
                              </v-col>
                              <v-col
                                style="width:calc(100% - 270px); flex-basis: unset;"
                              >
                                {{ item.cmpnm }}
                              </v-col>
                              <v-col
                                class="text-center"
                                style="width:100px; flex-basis: unset;"
                              >
                                <template v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 33">
                                  {{ item.directCount }} / {{ item.inDirectCount }}
                                </template>
                                <template v-else>
                                  {{ item.inDirectCount }}
                                </template>
                              </v-col>
                              <v-col
                                class="text-center"
                                style="width:100px; flex-basis: unset;"

                              >
                                {{ item.count }}
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <base-card class="mb-3">
                              <v-card-text>
                                <div class="mt-3">
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      항목
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.dealerKind == 33 ? '지사' : '대리점' }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      대리점ID
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.dealerId }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      등록일
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.createDt }}
                                    </p>
                                  </div>

                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <template v-if="loggedInUser.dealerKind == 33">
                                      <template v-if="payLoad.type == 'b'">
                                        <p
                                          class="text--disabled mb-1 text-12 mr-2"
                                          style="min-width: 100px"
                                        >
                                          모집대리점
                                        </p>
                                        <p
                                          class="text-success mb-1 text-12"
                                        >
                                          {{ item.bossBizName }}
                                        </p>
                                      </template>
                                      <template v-else>
                                        <p
                                          class="text--disabled mb-1 text-12 mr-2"
                                          style="min-width: 100px"
                                        >
                                          추천지사
                                        </p>
                                        <p
                                          class="text-success mb-1 text-12"
                                        >
                                          {{ item.recommendBizName }}
                                        </p>
                                      </template>

                                    </template>
                                    <template v-else>
                                      <p
                                        class="text--disabled mb-1 text-12 mr-2"
                                        style="min-width: 100px"
                                      >
                                        모집대리점
                                      </p>
                                      <p
                                        class="text-success mb-1 text-12"
                                      >
                                        {{ item.bossBizName }}
                                      </p>
                                    </template>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      대표자명
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.bprprr }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      주소
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 text-sm-caption"
                                    >
                                      {{ item.bplc }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      연락처
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 "
                                    >
                                      {{ item.mtelno }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      이메일
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 "
                                    >
                                      {{ item.email }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      계좌
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.bankName }} - {{ item.bankSerial }} - {{ item.bankUser }}
                                    </p>
                                  </div>
<!--
                                  <template v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 33">
                                    <div
                                      class="d-flex justify-space-between"
                                    >
                                      <p
                                        class="text--disabled mb-1 text-12 mr-2"
                                        style="min-width: 100px"
                                      >
                                        직접/간접모집대리점수
                                      </p>
                                      <p
                                        class="text-success mb-1 text-12"
                                      >
                                        {{ item.directCount }} / {{ item.inDirectCount }}
                                      </p>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div
                                      class="d-flex justify-space-between"
                                    >
                                      <p
                                        class="text--disabled mb-1 text-12 mr-2"
                                        style="min-width: 100px"
                                      >
                                        모집대리점수
                                      </p>
                                      <p
                                        class="text-success mb-1 text-12"
                                      >
                                        {{ item.inDirectCount }}
                                      </p>
                                    </div>
                                  </template>
                                  -->
                                </div>
                              </v-card-text>
                            </base-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            xl="12"
          >
            <v-container >
              <v-pagination
                v-model="current"
                class="my-4"
                :length="pageCount"
                :total-visible="10"
                @next="pageHandler()"
                @previous="pageHandler()"
                @input="pageHandler()"
              />
            </v-container>
          </v-col>
        </v-row>
      </v-col>


    </v-row>
  </div>
</template>

<script>
  import analyticOneCard from '@/components/card/AnalyticCardVersionOne'
  import analyticTwoCard from '@/components/card/AnalyticCardVersionTwo'
  import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'
  import SearchAdd from '@/components/base/SearchAdd'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { currentDay, dataType } from '@/filter/filter'

  export default {
    name: 'Dealer',
    created () {
      this.setSelectedMenu('대리점관리')
      this.dates = ['2010-01-01', currentDay()]
      this.initData()
      if (this.loggedInUser.dealerKind === 33) {
        this.selectedMenu = '소속대리점'
      } else {
        this.selectedMenu = '직접모집대리점'
      }
    },
    mounted () {

      const search_date_document = document.getElementById("search_date");
      search_date_document.addEventListener("click", (e) => {
        this.menuTwo = true;
      })


      this.payLoad.startNo = 0
      this.payLoad.endNo = 10
      this.payLoad.startDt = this.dates[0]
      this.payLoad.endDt = this.dates[1]
      this.payLoad.userId = this.loggedInUser.dealerId
      this.payLoad.dealerKind = this.loggedInUser.dealerKind
      this.fetchAgencyCount()

      if (this.$route.query.type === 'b') {
        this.selectedMenu = '소속대리점'
        this.searchFormEvent()
      } else if (this.$route.query.type === 'a') {
        this.selectedMenu = '추천지사'
        this.searchFormEvent()
      } else if (this.$route.query.type === 'c') {
        this.searchFormEvent()
      }

      if (this.loggedInUser.dealerKind === 34) {
        this.selectedMenu = '직접모집대리점'
      }

      if (this.loggedInUser.dealerKind === 33) {
        if (this.selectedMenu === '추천지사') this.payLoad.type = 'a'
        if (this.selectedMenu === '소속대리점') this.payLoad.type = 'b'
      }

      this.payLoad.startDt = this.dates[0]
      this.payLoad.endDt = this.dates[1]
      this.fetchAgencyList(this.payLoad).then(_ => {
        if (this.selectedMenu === '추천지사') {
          this.pageCount = Math.ceil(this.agencyCount.inDirectCount / this.pageSize)
        } else
          this.pageCount = Math.ceil(this.agencyCount.directCount / this.pageSize)

      })
    },
    components: {
      'analytic-one-card': analyticOneCard,
      'analytic-two-card': analyticTwoCard,
      CryptoCurrencyCard,
      SearchAdd,
    },
    data: () => ({
      selectedMenu: '',
      dropdownMenu: [
        {
          text: '소속대리점',
          type: 'A',
        },
        {
          text: '추천지사',
          type: 'B',
        },
      ],
      current: 1,
      pageSize: 10,
      pageCount: 1,
      dates: ['2022-04-01', '2022-04-20'],
      payLoad: {
        userId: '',
        dealerId: '',
        dealerName: '',
        dealerMemberName: '',
        startDt: '',
        endDt: '',
        dealerKind: '',
        startNo: 0, // 시작 페이지
        endNo: 10, // 종료 페이지 번호
        // type: 'a', // 지사인 경우 사용 소속지사 추천 지사 구분용 a 지사 c 추천지사
      },
      menuTwo: false,
      searchList: [
        {
          text: '전체',
          value: 'all',
        },
        {
          text: '대리점명',
          value: 'agency',
        },
        {
          text: '아이디',
          value: 'userId',
        },
        {
          text: '대표자명',
          value: 'name',
        },
      ],
      searchList2: [
        {
          text: '대리점/지사명',
          value: 'agency',
        },
        {
          text: '아이디',
          value: 'userId',
        },
        {
          text: '대표자명',
          value: 'name',
        },
      ],
    }),

    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
      ...mapGetters(['loggedInUser', 'agencyCountInfo', 'agencyBasicInfo', 'agencyList', 'agencyCount']),
      directCount () {
        return this.agencyCountInfo.directCount + '개'
      },
      inDirectCount () {
        return this.agencyCountInfo.inDirectCount + '개'
      },
    },
    methods: {
      ...mapActions(['fetchAgencyCount', 'fetchAgencyList']),
      ...mapMutations(['setAgencyList', 'setAgencyCount', 'toggleThemeLoadingState', 'setSelectedMenu']),
      initData () {
        if (this.dates.length === 0) {
          const today = dataType()
          // let preDay = new Date()
          // preDay.setDate(1)
          // //preDay.setMonth(preDay.getMonth() - 1)
          // preDay = dataType(preDay)
          this.dates = [today, today]
        }
        this.current = 1
        this.payLoad = {
          dealerKind: this.loggedInUser.dealerKind,
          userId: this.loggedInUser.dealerId,
          dealerId: '',
          dealerName: '',
          dealerMemberName: '',
          startDt: this.dates[0],
          endDt: this.dates[1],
          startNo: 1, // 시작 페이지
          endNo: this.pageSize, // 종료 페이지 번호
        }
        // vuex 초기화
        this.setAgencyList([])
        this.setAgencyCount = 0
      },
      searchName (name) {
        this.selectedMenu = name
      },
      searchFormEvent (arrObj) {
        if (!!arrObj && arrObj.length > 0) {
          for (const el of arrObj) {
            if (el.key === 'all') {
              this.payLoad.cmpnm = null
              this.payLoad.dealerId = null
              this.payLoad.bprprr = null

            }
            if (el.key === 'agency') {
              this.payLoad.cmpnm = el.value
              this.payLoad.dealerId = null
              this.payLoad.bprprr = null
            }
            if (el.key === 'userId') {
              this.payLoad.dealerId = el.value

              this.payLoad.cmpnm = null
              this.payLoad.bprprr = null

            }
            if (el.key === 'name') {
              this.payLoad.bprprr = el.value
              this.payLoad.cmpnm = null
              this.payLoad.dealerId = null

            }
          }
        }

        if (this.loggedInUser.dealerKind === 33) {
          if (this.selectedMenu === '추천지사') this.payLoad.type = 'a'
          if (this.selectedMenu === '소속대리점') this.payLoad.type = 'b'
        }

        this.payLoad.startDt = this.dates[0]
        this.payLoad.endDt = this.dates[1]
        this.fetchAgencyList(this.payLoad).then(_ => {
          if (this.selectedMenu === '추천지사') {
            this.pageCount = Math.ceil(this.agencyCount.inDirectCount / this.pageSize)
          } else
            this.pageCount = Math.ceil(this.agencyCount.directCount / this.pageSize)
        })
      },

      agencyTabClick (tabIndex) {
        if (tabIndex == 2) {
          this.payLoad.type = 'a'
          this.payLoad.startNo = 0
          this.payLoad.endNo = 10
          this.current = 1
        }

        if (tabIndex == 1) {
          this.payLoad.type = 'b'
          this.payLoad.startNo = 0
          this.payLoad.endNo = 10
          this.current = 1
        }
        if (this.loggedInUser.dealerKind === 33) {
          if (this.selectedMenu === '추천지사') this.payLoad.type = 'a'
          if (this.selectedMenu === '소속대리점') this.payLoad.type = 'b'
        }

        this.payLoad.bprprr = null
        this.payLoad.cmpnm = null
        this.payLoad.dealerId = null

        this.payLoad.startDt = this.dates[0]
        this.payLoad.endDt = this.dates[1]
        this.fetchAgencyList(this.payLoad).then(_ => {
          if (this.selectedMenu === '추천지사') {
            this.pageCount = Math.ceil(this.agencyCount.inDirectCount / this.pageSize)
          } else
            this.pageCount = Math.ceil(this.agencyCount.directCount / this.pageSize)

        })
      },
      pageHandler () {
        this.payLoad.startNo = (this.current - 1) * this.pageSize + 1
        this.payLoad.endNo = this.pageSize * this.current
        this.searchFormEvent()
      },
      datesSave (date) {
        date = date.sort()
        this.$refs.menuTwo.save(date)
      },
    },
  }
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
